import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import capitalize from 'lodash/capitalize'
import Helmet from 'react-helmet'

import Bio from '../components/Bio'
import Layout from '../components/layout'
import { rhythm } from '../utils/typography'
import styled from "styled-components"

const Nav = styled.nav`
  .navLink {
    color: rgb(60,60,60);
    text-decoration: none;

    &:hover {
      box-shadow: 0 1px 0 0 currentColor;
      color: #008bff;
    }

    &.active {
      color: #008bff;
    }
  }
`

const linkMap = [
  ['/', 'All'],
  ['/travel', 'travel'],
  ['/politics', 'politics'],
  ['/tech', 'tech']
]

class BlogIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(
      this,
      'props.data.site.siteMetadata.description'
    )
    const posts = get(this, 'props.data.allMarkdownRemark.edges')

    const { tag } = this.props.pageContext

    const NavLinks = linkMap.map(t => {
      const active = tag === t[1]
      const label = capitalize(t[1])
      const className = `navLink${active ? ' active' : ''}`
      return <Link key={t[0]} className={className} to={t[0]}>{label}</Link>
    })

    let NavLinksHtml = []

    for (let i = 0; i < NavLinks.length; i++) {
      const index = i * 2
      NavLinksHtml[index] = NavLinks[i]
      if (i !== NavLinks.length - 1) {
        NavLinksHtml[index + 1] = ' | '
      }
    }

    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[{ name: 'description', content: siteDescription }]}
          title={siteTitle}
        />
        <Bio />
        <Nav>
          {NavLinksHtml}
        </Nav>
        {posts.map(({ node }) => {
          const title = get(node, 'frontmatter.title') || node.fields.slug
          return (
            <div key={node.fields.slug}>
              <h3
                style={{
                  marginBottom: rhythm(1 / 4),
                }}
              >
                <Link style={{ boxShadow: 'none' }} to={node.fields.slug}>
                  {title}
                </Link>
              </h3>
              <small>{node.frontmatter.date}</small>
              <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
            </div>
          )
        })}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query($tag: String) {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
            tags
          }
        }
      }
    }
  }
`
